<template>
<div :id="`liquidacao-item-${ contrato.numeroContrato }`" class="modal modal-forms" tabindex="-2" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <small>Financiamento</small>
          <h2 class>{{ contrato.descricao }}</h2>
        </div>
        <div class="number">
          <small>Número do Contrato</small>
          <p>{{ contrato.numeroContrato }}</p>
        </div>
        <!-- <h4 class="modal-title">Dados do contrato</h4> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>   
      <div class="modal-body">

        <div>
          <div v-if="carregando" class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            Carregando dados do contrato ... 
          </div>
          <div v-if="mensagemContratoEmAtraso">
            Olá, verificamos aqui que você possui parcela(s) em atraso. Para regularização, entre em contato com a nossa Central de Atendimento pelos telefones 4020-3300 ou 0800 646 7676 - Atendimento de segunda a sexta, das 09:00 às 18:00 hrs.
          </div>
          <div class="alert alert-primary" role="alert" v-if="sucessoEnvioEmai">
            E-mail enviado com sucesso.
          </div>
          <p class="alert alert-warning p-2" v-if="errors.length">
            <ul>
              <li
                v-for="(error, index) in errors"
                v-bind:key="index"
              >{{ error }}</li>
            </ul>
          </p>
          <div v-if="pagina===0">
            <div class="responsive-table" v-if="carregado">
              <div class="col-12 mb-4 fs-sm-down-08x px-0 px-md-3">
                <div class="vencimento mb-2 pb-3 fs-sm-down-09x">
                  <p class="mb-0">Vencimento <input ref="vencDatePicker" v-model="vencimento" type="text" style="visibility:hidden; width:1px;" > {{ vencimento | formatDate }}</p>
                  
                  <!-- <div ref="vencDatePicker" class="vencDatePicker">Aqui</div> -->
                  <div class="ml-4">
                    <a class="change-date" href="#" v-on:click="selecionaDataVenc">
                      <i class="far fa-calendar-alt"></i> Trocar data
                    </a>
                  </div>
                </div>
              </div>
              <table class="table table-hover text-center fs-08x">
                <thead>
                  <tr>
                    <th class="py-md-3" scope="col">QUITAR</th>
                    <th class="py-md-3" scope="col">PARCELA</th>
                    <th class="py-md-3" scope="col">VENCIMENTO</th>
                    <th class="py-md-3" scope="col">VALOR</th>
                    <th class="py-md-3" scope="col">VALOR PRESENTE</th>
                    <th class="py-md-3" scope="col">SITUAÇÃO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(parcela, index) in parcelas" v-bind:key="index">
                    <td data-title="Quitar">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="`parcela-${contrato.numeroContrato}-${ parcela.parcela }`"
                          :value="`${parcela.parcela}`"
                          v-model="parcelasSelecionadas"
                        />
                        <label
                          class="custom-control-label"
                          :for="`parcela-${contrato.numeroContrato}-${ parcela.parcela }`"
                        ></label>
                      </div>
                    </td>
                    <td data-title="Parcela">{{ parcela.parcela }} / {{ contrato.qtdParcelas }}</td>
                    <td data-title="Vencimento">{{ parcela.vencimento | formatDate }}</td>
                    <td data-title="Valor">{{ parcela.valor | formatCurrency }}</td>
                    <td data-title="Valor">{{ parcela.valorAtualizado | formatCurrency }}</td>
                    <td data-title="Situação">
                      <span
                        :class="{ 'warning': parcela.situacao == 'Atrasada' }"
                      >{{ parcela.situacao}}</span>
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th class="py-md-3" scope="col"></th>
                    <th class="py-md-3" scope="col" colspan="3"><div class="footer-total-rigth">SOMA DAS PARCELAS</div></th>
                    <th class="py-md-3" scope="col">{{ valorTotalParcelas | formatCurrency}}</th>
                    <th class="py-md-3" scope="col"></th>
                  </tr>
                </thead>
              </table>
              <div class="col-12 col-lg-12 col-xl-12">
                <div class="button-box">
                  <button
                    type="button"
                    class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2"
                    v-on:click="simular"
                  >
                    <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoSimular">
                      <span class="sr-only">Loading...</span>
                    </div>
                    Próximo
                  </button>
                  <!-- <button type="button" class="btn btn-link px-0 pt-3">Imprimir boleto(s)</button> -->
                </div>
              </div>
            </div>
          </div>
          <div v-if="pagina===1">

            <div class="col-12 mb-4 fs-sm-down-08x px-0 px-md-3">
              <table class="table table-hover table-left-right">
                <tbody>
                  <tr>
                    <td>Parcelas:</td>
                    <td>{{ listaParcelas }}</td>
                  </tr>
                  <tr>
                    <td>Total de Parcelas:</td>
                    <td>{{ totalParcelas }}</td>
                  </tr>
                  <tr>
                    <td>Valor total das parcelas:</td>
                    <td>{{ simulacao.valorTotal | formatCurrency }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fs-1hx font-weight-bolder">Total de desconto</span>
                    </td>
                    <td>
                      <span class="fs-1hx font-weight-bolder">- {{ simulacao.totalDesconto | formatCurrency}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fs-1hx font-weight-bolder">Total a pagar</span>
                    </td>
                    <td>
                      <span class="fs-1hx font-weight-bolder">{{ simulacao.totalPagamento | formatCurrency}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr>
              
              <div class="row">
                <div class="col-12 col-lg-12 col-xl-12">
                  <div class="button-box">
                    <button
                      type="button"
                      class="btn btn-primary fs-08x mb-1"
                      v-on:click="novaSimualcao"
                    >Nova simulação</button>
                    <button
                      type="button"
                      class="btn btn-primary fs-08x mb-1"
                      v-on:click="enviaEmailBoleto"
                    >
                      <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoEmailBoleto">
                        <span class="sr-only">Loading...</span>
                      </div>
                      Enviar boleto por e-mail
                    </button>
                    <button
                      type="button"
                      class="btn btn-link fs-08x px-3 my-2"
                      v-on:click="downloadBoleto"
                    >
                      <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoImprimirBoleto">
                        <span class="sr-only">Loading...</span>
                      </div>
                    
                      Imprimir boleto
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div v-if="pagina===2">
            Página 2
          </div>
          <ParcelaEnvioEmail ref="envioEmailModal" :simulacao="simulacao"></ParcelaEnvioEmail>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
// import Contratos from "../api_portal_auto_atendimento/src/model/Contratos";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";
import moment from "moment";
import ParcelaEnvioEmail from '../components/ParcelaEnvioEmail';

export default {
  name: "ParcelaTabContent",
  components: {
    ParcelaEnvioEmail
  },
  props: {
    contrato: Object
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      parcelas: [],
      parcelasSelecionadas: [],
      pagina:0,
      simulacao:{},
      processing: true,
      errors: [],
      vencimento: '',
      buttonPressed: 0,
      contratoEmAtraso: false,
      sucessoEnvioEmai: false,
      valorTotalParcelas:0,
      calendarioCriado:false,
      mostraContrato: false,
      cpfCnpj:'',
      envioEmailModal:Object
    };
  },
  watch:{
    parcelasSelecionadas: function() {
      if (this.parcelas) {  
        var total = 0.0;
        this.parcelas.forEach(p => {
          if (this.parcelasSelecionadas.indexOf(p.parcela) >= 0) {
            total += p.valorAtualizado; 
          }
        });
        this.valorTotalParcelas = total;
      } else {
        this.valorTotalParcelas = 0.0
      }
    }
  },
  computed: {
    carregando: function() {
      return this.processing && this.buttonPressed == 0;
    },
    carregandoSimular: function() {
      return this.processing && this.buttonPressed == 1;
    },
    carregandoImprimirBoleto: function() {
      return this.processing && this.buttonPressed == 2;
    },
    carregandoEmailBoleto: function() {
      return this.processing && this.buttonPressed == 3;
    },
    carregado: function() {
      return (!this.processing || this.buttonPressed != 0) && !this.contratoEmAtraso;
    },
    totalParcelas:function () {
      if (this.simulacao){
        if (this.simulacao.totalParcelas) {
          return this.simulacao.totalParcelas.length
        }
      }
      return 0;
    },
    listaParcelas: function() {
      if (this.simulacao.totalParcelas) {
        let parcList = this.simulacao.totalParcelas.map(function(p){  return p.parcela; });
        return parcList.join(", ");
      } else {
        return '';
      }
    },
    isDisabled: function() {
      return this.processing;
    },

    mensagemContratoEmAtraso: function() {
      return this.contratoEmAtraso != '';
    },
    mensagemContratoLojista: function(){
      return this.mostraContrato;
    }
    // ,
    // valorTotalParcelas: function () {
    //   if (this.parcelas) {  
    //     var total = 0;
    //     this.parcelas.forEach(p => {
    //       if (p.parcela in parcelas) {
    //         total += p.valor; 
    //       }
    //     });
    //     return total;
    //   } else {
    //     return 0;
    //   }
    // }
  },
  methods: {
    selecionaDataVenc: function (event) {
      if (event) {
        event.preventDefault();
      }
      let self = this;
      $(this.$refs.vencDatePicker).datepicker({
        daysOfWeekDisabled: [0,6],
        format: 'yyyy-mm-dd',
        startDate: new Date(),
        autoclose: true,
        language: "pt-BR",
      }).unbind('changeDate').on('changeDate',function(e) {
          self.setVencimento(moment(e.date).format("YYYY-MM-DD"));
      }).datepicker("show");
    },
    novaSimualcao: function(event) {
      if (event) {
        event.preventDefault();
      }
      this.simulacao = null;
      this.parcelasSelecionadas = [];
      this.pagina = 0;
    },
    parserException(response, defaultMessage = 'Erro ao realizar operação.') {
      if (response && response.text) {
        try {
          let listOfErrors = JSON.parse(response.text);
          listOfErrors.forEach(error => {
            if (error.code && error.code == 901) {
              this.contratoEmAtraso = true;
            }

            this.errors.push(error.message);
          });
        } catch (e) {
          this.errors.push(defaultMessage);
        }
      } else {
        this.errors.push(defaultMessage);
      }
      if (this.errors.length > 0){
        this.parcelas = [];
        this.parcelasSelecionadas = [];
        this.simulacao = {};
      }
      if (this.contratoEmAtraso) {
        this.errors = [];
      }
    },
    simular: function(event) {
      if (event) {
        event.preventDefault();
      }
      this.processing = true;
      this.buttonPressed = 1;
      this.errors = [];

      if (this.parcelasSelecionadas.length == 0){
        this.errors.push('Selecione pelo menos uma parcela para realizar simulação.')
        this.processing = false;
        this.buttonPressed = 0;
        return;
      }
      
      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()
      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;
      let contrato = this.contrato.numeroContrato; // String |
      let dataPagamento = this.vencimento;
      let numeroParcelas = this.parcelasSelecionadas;

      apiContrato.consultaParcelasSimulacao(empresa, agencia, contrato, numeroParcelas, dataPagamento, (error, data, response) => {
        this.processing = false;
        this.buttonPressed = 0;
        if (error) {
          this.parserException(response, 'Erro ao realizar simulação.');
        } else {
          this.simulacao = data;
          this.pagina = 1;
        }
      });
    },
    downloadBoleto: function(event) {
      if (event) {
        event.preventDefault();
      }

      this.processing = true;
      this.buttonPressed = 2;
    
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.timeout = 60000 * 3;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()

      let apiCobranca = new ApiPortalAutoAtendimento.CobrancaApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let simulacao = this.simulacao.simulacao; 

      apiCobranca.boleto(empresa, simulacao, (error, data, response) => {
        this.processing = false;
        this.buttonPressed = 0;
        if (error) {
          this.parserException(response, 'Caso não consiga visualizar seu contrato, solicitar o documento pelo telefone 4020 3300. - Erro ao realizar download do boleto.');
        } else {
          let pdfBase64 = data.boletoPdf;
          this.download(pdfBase64, `boleto-${this.contrato.numeroContrato}.pdf`);
        }
      });
    },
    download(base64String, filename) {
      
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      var blob = new Blob([byteArray], { type: 'application/pdf' });
      if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
          window.navigator.msSaveOrOpenBlob(blob, filename);
      }
      else {
          var a = window.document.createElement("a");
          window.URL = window.webkitURL || window.URL;
          a.href = window.URL.createObjectURL(blob, { type: "application/pdf", oneTimeOnly: true  });
          a.download = filename;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
          document.body.removeChild(a);
      }
    },
    enviaEmailBoleto() {
      if (event) {
        event.preventDefault();
      }

      this.$refs.envioEmailModal.show();
      // console.log(this.$refs.envioEmailModal);
      // console.log(this.envioEmailModal);

      // this.processing = true;
      // this.buttonPressed = 3;
    
      // let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      // defaultClient.timeout = 60000 * 2;
      // defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()

      // let apiCobranca = new ApiPortalAutoAtendimento.CobrancaApi();

      // let userData = this.authService.userData();
      // let empresa = userData.Empresa;
      // let simulacao = this.simulacao.simulacao; 


      // apiCobranca.emailBoleto(empresa, simulacao, (error, data, response) => {
      //   this.processing = false;
      //   this.buttonPressed = 0;
      //   if (error) {
      //     this.parserException(response, 'Erro ao enviar e-mail do boleto.');
      //   } else {
      //     this.sucessoEnvioEmai = true;
      //     let self = this;
      //     setInterval(() => {
      //       self.sucessoEnvioEmai = false;
      //     }, 3000);
      //   }
      // });
    },
    getNextBusinessDay() {

      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()

      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;

      apiContrato.proximoDiaUtil(empresa, agencia, this.vencimento, (error, data, response) => {
        if (error) {
          this.parserException(response, 'Erro ao obter próximo dia útil.');
        } else {
          this.vencimento = moment(data).format("YYYY-MM-DD");
          this.loadParcelas();
        }
      });
    },
    getCurrentDate() {

      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()

      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;

      apiContrato.diaUtil(empresa, agencia, this.vencimento, (error, data, response) => {
        if (error) {
          this.parserException(response, 'Erro ao validar vencimento.');
        } else {
          if(data) {
            this.loadParcelas();
          } else {
            this.getNextBusinessDay();
          }
        }
      });
    },
    loadParcelas: function() {

      this.processing = true;
      this.contratoEmAtraso = false;
      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()
      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;
      let contrato = this.contrato.numeroContrato; // String |
      let dataPagamento = moment(this.vencimento).format("YYYY-MM-DD");
      let situacao = "A"; // String |
      this.mostraContrato = false;

      apiContrato.consultaParcelas(
        empresa,
        agencia,
        contrato,
        dataPagamento,
        situacao,
        (error, data, response) => {
          this.processing = false;
          if (!error) {
            this.parcelas = data;
          } else {
            this.parserException(response, 'Erro ao obter dados do contrato.');
          }
        }
      );
    },
    setVencimento(newDate) {
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()

      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;

      this.errors = [];

      apiContrato.diaUtil(empresa, agencia, newDate, (error, data, response) => {
        if (error) {
          this.parserException(response, 'Erro ao validar vencimento.');
        } else {
          if(data) {
            this.vencimento = newDate
            this.loadParcelas();
          } else {
            this.errors = [];
            this.errors.push('A data selecionada não é um dia útil.');
          }
        }
      });
    }
  },
  updated() {
    if (!this.calendarioCriado) {
      let self = this;
      $(this.$refs.vencDatePicker).datepicker({
        daysOfWeekDisabled: [0,6],
        format: 'yyyy-mm-dd',
        startDate: new Date(),
        autoclose: true,
        language: "pt-BR",
      }).unbind('changeDate').on('changeDate',function(e) {
          self.setVencimento(moment(e.date).format("YYYY-MM-DD"));
      });
      this.calendarioCriado = true;
    }
  },
  mounted() {
    this.vencimento = moment().format("YYYY-MM-DD");
    this.getCurrentDate();

    let userData = this.authService.userData();
    this.cpfCnpj = localStorage.cpfCnpj;

  }
};
</script>

<style scoped>



</style>